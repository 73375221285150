import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Boxes from "../components/Boxes"
import Blogs from "../components/Blogs"
import SEO from "../components/SEO"
export default ({ data }) => {
  const {
    allStrapiBoxes: { nodes: boxes },
    allStrapiBlogs: { nodes: blogs },
  } = data

  return (
    <Layout>
      <SEO title="Home" description="this is our home page" />

      <Boxes boxes={boxes} title="Boxes" showLink />
      <Blogs blogs={blogs} title="Blogs" showLink />
    </Layout>
  )
}
export const query = graphql`
  {
    allStrapiBoxes(sort: { fields: date, order: DESC }, limit: 3) {
      nodes {
        id
        Description
        Title
        Slug
        category
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        Os {
          id
          title
        }
      }
    }
    allStrapiBlogs(sort: { fields: date, order: DESC }, limit: 3) {
      nodes {
        Slug
        Info
        Description
        date(formatString: "MMMM Do, YYYY")
        id
        Title
        category
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
